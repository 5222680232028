<template>
    <div class="live-room-manage">
        <div class="live-title">直播间管理</div>
        <el-table
            :data="liveList"
            :class="liveList.length == 0 ? 'no-data-table' : ''"
            :header-cell-style="{ fontWeight: 'normal', height: '60px', color: '#fff', fontSize: '14px', background: '#0C0E3F' }"
            :cell-style="{ fontSize: '14px', color: '#fff', height: '80px', background: '#070932' }"
        >
            <el-table-column prop="nickname" label="主播信息">
                <template slot-scope="scope">
                    <div class="info-box">
                        <img
                            class="info-circle"
                            :src="scope.row.avatar ? scope.row.avatar : require('../../../assets/image/blank_headPic.png')"
                            alt=""
                        />
                        <div class="info-name">{{ scope.row.nickname }}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="title" label="直播间信息" align="center">
                <template slot-scope="scope">
                    <div class="info-box">
                        <img
                            class="info-square"
                            :src="scope.row.cover ? scope.row.cover : require('../../../assets/image/student/live_manage.png')"
                            alt=""
                        />
                        <div class="info-name" style="width: 300px">{{ scope.row.title }}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <div class="op-content">
                        <el-button class="op-btn" @click="toDegger">直播调试</el-button>
                        <div class="op-btn" @click="startLive(scope.row)">开播</div>
                        <div class="op-btn" @click="editBtn(scope.row)">编辑</div>
                        <div class="op-btn danger" @click="delBtn(scope.row)">删除</div>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="empty-data" v-if="liveList.length == 0">
            <img class="no-data" src="../../../assets/image/studentCoupon/no_data_coupon.png" alt="" />
            <div class="no-data-tips">暂无直播间</div>
            <el-button type="primary" @click="createLive">创建直播间</el-button>
        </div>
        <el-dialog title="小程序推流入口" :visible.sync="dialogVisible" width="500px" top="26vh" :before-close="handleClose">
            <div class="dialog-content">
                <span class="dialog-title">主播通过微信扫码进入直播小程序进行推流</span>
                <div class="qr-content">
                    <div id="qrCode" ref="qrCodeDiv" class="qrCodeDiv"></div>
                </div>
                <div class="bottom-content">
                    <el-button type="primary" @click="goToLive">进入电脑端</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { liveRoom_list, liveRoom_del } from "@/utils/apis";
import QRCode from "qrcodejs2";

export default {
    name: "LiveRoomManage",
    data() {
        return {
            liveList: [],
            dialogVisible: false,
        };
    },
    computed: {
        isDev() {
            return process.env.NODE_ENV == "development";
        },
    },
    mounted() {
        this.getList();
    },
    methods: {
        toDegger() {
            this.$router.push({ path: "/liveDebug", query: { form: 1 } });
        },
        getList() {
            liveRoom_list().then((res) => {
                this.liveList = res.data;
            });
        },
        startLive(row) {
            if (row.live_type === 2) {
                this.$router.push("/liveRoom");
            } else {
                let token = localStorage.getItem("studentToken");
                const hosturl = localStorage.getItem("host_url");
                // let url = 'https://live.e-class.me/live?token=' + token
                // let url = "https://onlive.e-class.me/live?token=" + token;
                const getItem = (key) => localStorage.getItem(key) * 1;
                const qrParams = [
                    this.liveList[0].id,
                    getItem("studentId"),
                    getItem("scriptId"),
                    getItem("train_id"),
                    getItem("advert_id"),
                ];
                const valid = qrParams.some((item) => !item);
                if (valid) {
                    this.$message.error("缺少必要参数，创建二维码失败");
                    return void 0;
                }
                const [room_id, user_id, script_id, train_id, advert_id] = qrParams;
                this.dialogVisible = true;
                let url = `${hosturl}live?placeholder=true&token=${token}&room_id=${room_id}&user_id=${user_id}&script_id=${script_id}&train_id=${train_id}&advert_id=${advert_id}`;
                // let url = `${hosturl}live?placeholder=true&token=${123456}&room_id=${276}&user_id=${69}&script_id=${225}&train_id=${218}&advert_id=${12}`;
                this.$nextTick(function () {
                    new QRCode(this.$refs.qrCodeDiv, {
                        text: url,
                        width: 222,
                        height: 222,
                        colorDark: "#333333", //二维码颜色
                        colorLight: "#ffffff", //二维码背景色
                        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
                    });
                });
            }
        },
        goToLive() {
            this.$router.push("/liveRoom");
        },
        editBtn(row) {
            this.$router.push({
                path: "/liveRoomManage/createLive",
                query: {
                    id: row.id,
                },
            });
        },
        delBtn(row) {
            this.$confirm("删除后将无法恢复，确定要删除？", "提示消息", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                customClass: "msgBoxClass",
            })
                .then(() => {
                    let param = {
                        id: row.id,
                    };
                    liveRoom_del(param)
                        .then((res) => {
                            this.$message.success("删除成功");
                            this.getList();
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                })
                .catch(() => {
                    this.$message.info("已取消删除");
                });
        },
        createLive() {
            this.$router.push("/liveRoomManage/createLive");
        },
        handleClose() {
            document.getElementById("qrCode").innerHTML = "";
            this.dialogVisible = false;
        },
    },
};
</script>

<style scoped lang="scss">
.live-room-manage {
    padding: 20px;
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;

    .live-title {
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        margin-bottom: 80px;
    }
}

.no-data-table {
    height: 60px !important;
}

.el-table {
    width: 100%;
    background: #070932;
    min-height: 200px;
    height: 200px;
    flex: unset !important;

    &:before {
        content: none;
    }

    ::v-deep .el-table__empty-block {
        display: none;
    }

    ::v-deep .el-table__body-wrapper {
        background: #070932;
    }

    ::v-deep th.el-table__cell {
        &:first-child {
            & > .cell {
                padding-left: 70px;
            }
        }

        &.is-leaf {
            border-bottom: none;
        }
    }

    ::v-deep td.el-table__cell {
        border-bottom: none;

        &:first-child {
            & > .cell {
                padding-left: 70px;
            }
        }
    }
}

.info-box {
    display: flex;
    align-items: center;

    .info-circle {
        width: 54px;
        height: 54px;
        border-radius: 50%;
    }

    .info-square {
        width: 100px;
        height: 100px;
        margin-right: 30px;
    }

    .info-name {
        width: 200px;
        margin-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
    }
}

.op-content {
    display: flex;
    justify-content: center;

    .op-btn + .op-btn {
        margin-left: 20px;
    }

    .op-btn {
        width: 76px;
        height: 30px;
        background: rgba(75, 69, 255, 0.1);
        border: 1px solid #4b45ff;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #4b45ff;
        cursor: pointer;

        &.danger {
            border: 1px solid #ff3e6c;
            background: rgba(255, 62, 108, 0.1);
            color: #ff3e6c;
        }
    }
}

.empty-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 117px;

    .no-data {
        width: 388px;
        height: 326px;
    }

    .no-data-tips {
        margin: 58px 0 34px;
        color: #fff;
    }

    .el-button {
        background: #4b45ff;
    }
}

::v-deep .el-dialog {
    background: #070932;

    .el-dialog__title {
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        position: relative;
        margin-left: 20px;

        &:before {
            position: absolute;
            content: "";
            width: 4px;
            height: 12px;
            background: #4b45ff;
            left: -15px;
            top: 2px;
        }
    }
}

.dialog-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .dialog-title {
        font-size: 16px;
        color: #fff;
        margin-top: 18px;
    }

    .qr-content {
        width: 222px;
        height: 222px;
        margin: 30px 0 44px;

        .qrCodeDiv {
            height: 100%;
            width: 100%;
        }
    }
}
</style>
